.timeline{
    padding: 0 !important;
}

.timeline .timeline_header{
    padding-top: 7px;
}

.timeline .timeline_firstItem{
    min-height: 80px !important;
}

.timeline .MuiTimelineItem-root {
	min-height: 0px;
}

.timeline .MuiTimelineItem-missingOppositeContent:before {
    display: none;
}

.timeline .timeline_dot_header{
    color: black;
    background-color: var(--main-color);
    font-size: small;
    padding: 10px;
}

/* Remaining Items */

.timeline .timeline_dot{
    color: black !important;
    border-color: var(--main-color) !important;
    padding: 2px !important;
}

.timeline .MuiTimelineConnecter-root{
    background-color: #eee !important;
}

.timeline .timeline_dot_header > .MuiSvgIcon-root{
	font-size: 20px;
	color: darkslategrey;
}

.timeline .separator_padding{
	padding-left: 19px !important;
}

.timelime .timeline_content{
	padding-top: 0px !important;
	margin-bottom: 20px
}

.footer{
	background-color: #1e1e1e;
	color: white;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	min-height: 70px;
	border-radius: 8px;
	padding: 20px 25px;
}

.footer .footer_copyright {
	font-size: small;
	text-align: end;
	color : #9c9c9c;
}

.footer .footer_copyright > a {
	text-decoration: none !important;
	color: var(--main-color);
}