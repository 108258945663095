.profile{
    background-color: white;
    border-radius: 6px;
    width: 100%;
    display: inline-block;
}

.profile_name{
    line-height: 18px;
    padding: 20px;
}

.profile_name .name{
    text-transform: uppercase;
    font-size: 17px;
    font-weight: bold;
}

.profile_name .title{
    font-size: 13px;
    color: #777
}

.profile_image{
    margin-top: -10px;
    -webkit-clip-path: polygon(0 9%, 100% 0, 100% 94%, 0% 100%);
    clip-path: polygon(0 9%,100% 0,100% 94%, 0% 100%)
}

.profile_image img{
    width: 100%;
}

.profile_information{
    top:0;
    left:0;
    margin: -44px 10px 30px;
}

.profile_information .timeline .timeline_content{
    margin-top: 2px;
    margin-bottom: 5px;
}

.timelineItem_text{
    font-size: 13.5px !important;
    color: #787878 ;
    font-weight: 400 !important;
}

.timelineItem_text > a{
    color: #787878 ;
    text-decoration: none;
}

.timelineItem_text > span{
    color: black;
}

.profile .btn_container{
    width: 100%;
    display: flex !important;
    justify-content: center;
    margin-top: 20px;
}